import Image from 'next/image'

import Button from './Button'
import { Container } from './Container'
import backgroundImage from '@/images/background-call-to-action.jpg'

export function CallToAction() {
  return (
    <section
      id="get-started-today"
      className="relative overflow-hidden bg-blue-600 py-32"
    >
      <div
        className="absolute top-1/2 left-1/2 max-w-none -translate-x-1/2 -translate-y-1/2 opacity-40"
      >
        <Image
          src={backgroundImage}
          alt=""
          width={2347}
          height={1244}
          unoptimized
        />
      </div>
      <Container className="relative">
        <div className="flex flex-col justify-center mx-auto max-w-lg text-center">
          <h2 className="font-display text-3xl tracking-tight text-white sm:text-4xl">
            Get started in one click
          </h2>
          <p className="mt-4 text-lg tracking-tight text-white">
            Take a quick demo or start a Retro with your team. We know you'll love it. It's one click away.
          </p>
          <div className="flex justify-center">
            <Button isTertiary className="mt-10 bg-white">
              <a target="_blank" rel="noopener noreferrer" href="/retro">
                Start a Retro
              </a>
            </Button>
          </div>
        </div>
      </Container>
    </section>
  )
}
